import { CalendarIcon, UserIcon, XCircleIcon } from '@heroicons/react/outline'
import { PlayIcon, PlusIcon } from '@heroicons/react/solid'
import React, { useMemo, useState } from 'react'
import { formatInTimeZone } from 'date-fns-tz'
import type { DateArray } from 'ics'
import { createEvent } from 'ics'

import {
  primaryButtonClassSmall,
  tertiaryButtonClassSmall,
} from '../constants/classConstants'
import type { Session } from '../types/Session'
import CancelBookingModal from './Modals/CancelBookingModal'
import { useToastContext } from '../contexts/ToastContext'
import { LICENSE_CREDENTIAL } from '../constants/values'
import { SERVICE_LINES_ARRAY } from '../constants/serviceLine'
import type { ServiceLine } from '../types/ServiceLine'
import { useAuth } from '../contexts/AuthProvider'
import INFO from '../assets/icons/info.svg'
import { isValidPastSession } from '../helpers/bookSessionStatusUtils'
import { capitalize, formatPossessiveNoun, newDate } from '../helpers/generic'
import type { CarePlan, Condition, Patient } from '../types/Patient'
import Tooltip from './Tooltip'
import type { ResultGetProviders } from '../queries/booking/UseGetProviders'
import useGetProviders from '../queries/booking/UseGetProviders'
import type { Therapist } from '../types/Therapist'
import SCHOOL_ICON from '../assets/icons/School.svg'
import USER_ICON from '../assets/icons/UserIcon.svg'
import THERAPY_SVG from '../assets/icons/therapy.svg'
import trackMixPanel, { MIXPANEL_EVENT } from '../hooks/useMixPanel'

const SessionComponent: React.FC<{
  session: Session
  minimal?: boolean // hides name, adds borders, used in UpcomingSessions
  showStatus?: boolean // hides name, buttons, no borders and used in PreviousSessions
  showPatientNameWithStatus?: boolean // shows patient name even if showStatus is true
  showInfo?: boolean // shows date, therapist, borders, info and used in AlreadyBooked page
}> = ({
  session,
  minimal = false,
  showStatus = false,
  showInfo = false,
  showPatientNameWithStatus = false,
}) => {
  const { user } = useAuth()
  const patient = user.roster.find((p: Patient) => p.id === session?.patientId)
  const { data: resultProviders } = useGetProviders({
    clientId: user?.data?.clientId,
    patients: user?.roster,
    enabled: Boolean(user?.roster?.length),
  })

  const [isOpenCancelBookingDialog, setIsOpenCancelBookingDialog] =
    useState<boolean>(false)
  const addToast = useToastContext()

  const isStartZoomButtonDisabled = useMemo(() => {
    if (!session?.zoomLink) return true

    const currentDate = new Date()
    const targetDate = new Date(session?.startTime)
    const fifteenMinutes = 900000 // 15 minutes in milliseconds

    // Check if the endTime is in the past
    const endTimeIsPast = new Date(session?.endTime) < currentDate

    // Check if it's more than 15 minutes before the start time
    const isLessThan15Minutes: boolean =
      currentDate.getTime() < targetDate.getTime() - fifteenMinutes

    return endTimeIsPast || isLessThan15Minutes
  }, [session])

  const addToCalendar = () => {
    const date = newDate(new Date(session?.startTime), patient?.timeZone)
    const sessionDateArray: DateArray = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
    ]

    const start = new Date(
      '1970-01-01 ' +
        formatInTimeZone(session?.startTime, patient?.timeZone, 'hh:mm aa')
    )
    const end = new Date(
      '1970-01-01 ' +
        formatInTimeZone(session?.endTime, patient?.timeZone, 'hh:mm aa')
    )

    const durationInMinutes = (end.getTime() - start.getTime()) / (1000 * 60)

    createEvent(
      {
        start: sessionDateArray,
        duration: { hours: 0, minutes: durationInMinutes },
        title: 'Huddle Up Appointment',
        description: `${session?.type} Appointment with ${session?.therapist?.preferredName}`,
        location:
          session?.zoomLink ||
          'Please view session confirmation email for Zoom link.',
        organizer: {
          name: session?.therapist?.preferredName,
          email: session?.therapist?.email,
        },
      },
      (error, value) => {
        if (error) {
          addToast('error', 'Something went wrong.')
          return
        }
        const blob = new Blob([value], { type: 'text/plain;charset=utf-8' })
        const link = document.createElement('a')
        link.download = 'dotcomtherapy-event.ics'
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
      }
    )

    trackMixPanel({
      eventName: MIXPANEL_EVENT.CALENDAR_INVITE_ADDED,
      properties: {
        source: patient.onboardingStep ? 'Onboarding' : 'Booking',
        inviteType: 'Session',
      },
    })
  }

  const startZoom = () => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.LINK_OPENED,
      properties: {
        type: 'Session Zoom',
      },
    })
    window.open(session?.zoomLink, '_blank')
  }

  const durationInMinutes: number = useMemo(() => {
    const start = new Date(
      '1970-01-01 ' +
        formatInTimeZone(session?.startTime, patient?.timeZone, 'hh:mm aa')
    )
    const end = new Date(
      '1970-01-01 ' +
        formatInTimeZone(session?.endTime, patient?.timeZone, 'hh:mm aa')
    )
    return (end.getTime() - start.getTime()) / (1000 * 60)
  }, [session])

  const sessionIsInThePast = isValidPastSession(session, patient)

  const currentCondition: Condition = patient?.conditions?.find(
    (c: Condition) => c.id === session?.conditionId
  )

  const currentCarePlan: CarePlan = currentCondition.carePlans.find(
    (cp: CarePlan) => cp.id === session?.carePlanId
  )

  const currentCarePlanProviderName: string = resultProviders
    .find((rp: ResultGetProviders) => rp.patient?.id === session?.patientId)
    ?.therapists.find(
      (t: Therapist) => t.id === currentCarePlan?.providerId
    )?.preferredName

  const differentUserWhoScheduled: boolean =
    session?.scheduledBy?.id && session?.scheduledBy?.id !== user.data.id

  const canCancelBooking = patient.isSeparateLogin
    ? patient.ableToBookAndCancel
    : true

  if (!session || !patient) return

  return (
    <div
      className={`
      ${
        minimal || showInfo
          ? 'rounded-2xl border p-6 xs:p-3'
          : 'border-b pb-8 last:border-0 last:pb-0 xs:pb-4'
      }
      ${showStatus || showInfo ? 'grid-cols-1' : 'grid-cols-1 xl:grid-cols-2'}
        grid gap-2 border-components-fields text-text-primary`}
    >
      {/* Left */}
      <div
        className={`${
          showInfo ? 'gap-8 xs:gap-4' : 'gap-4 xs:gap-2'
        } flex flex-col `}
      >
        {/* Patient name */}
        <div
          className={`${
            minimal || (showStatus && !showPatientNameWithStatus) || showInfo
              ? 'hidden'
              : ''
          } flex items-center gap-2`}
        >
          <UserIcon className="h-5 w-5 text-text-label" />{' '}
          <p className="text-base font-semibold xs:text-sm">
            {session?.patientName}
          </p>
        </div>

        {/* Date */}
        <div className="flex items-center gap-2 xs:flex-col xs:items-start xs:gap-0">
          {/* Actual date&time */}
          <div className="flex items-center gap-2 xs:items-start">
            <CalendarIcon className="h-5 w-5 text-text-label" />
            <div className="flex flex-wrap gap-x-2 xs:ml-1">
              <p className="text-base font-semibold xs:whitespace-pre-wrap xs:text-sm">
                {formatInTimeZone(
                  session?.startTime,
                  patient?.timeZone,
                  "EEEE',' MMMM d',' yyyy"
                )}
              </p>
              <p className="font-normal xs:text-sm">{`${formatInTimeZone(
                session?.startTime,
                patient?.timeZone,
                'hh:mm aa'
              )} - ${formatInTimeZone(
                session?.endTime,
                patient?.timeZone,
                'hh:mm aa'
              )} ${formatInTimeZone(new Date(), patient?.timeZone, 'zzz')}`}</p>
            </div>
          </div>

          {/* Show status */}
          {showStatus && (
            <div className="rounded-lg border border-components-fields sm:py-2 sm:px-4 xs:py-1 xs:px-2">
              <p className="text-sm font-semibold capitalize text-text-secondary">
                {capitalize(session?.status?.replace('_', ' '))}
              </p>
            </div>
          )}
        </div>

        {/* Therapist */}
        <div className="flex flex-col justify-center">
          <div className="flex gap-2">
            <img src={THERAPY_SVG} className="h-5 w-5" alt="therapy-svg" />{' '}
            <p className="text-base font-semibold capitalize xs:text-sm">
              {session?.name}{' '}
              {showInfo ? (
                <span>- {durationInMinutes} Min</span>
              ) : (
                <span className="font-normal">with</span>
              )}
            </p>
          </div>

          <div className="my-2 ml-8 flex items-center gap-6 xs:ml-6 xs:gap-2">
            <img
              src={session?.therapist.src}
              className="h-11 w-11 rounded-full border-4 border-components-paleBlue object-cover"
              alt="therapist"
            />
            <div className="text-sm text-text-secondary">
              <p className="font-semibold">
                {session?.therapist.preferredName}
              </p>
              <p className="text-label text-left text-xs font-semibold sm:text-base">
                {session?.therapist?.licenseCredential ||
                  LICENSE_CREDENTIAL[
                    SERVICE_LINES_ARRAY.find(
                      (line: ServiceLine) =>
                        line.displayName === session?.therapist.serviceLine
                    )?.serviceType
                  ]}
              </p>
              {currentCondition.isIep &&
                Boolean(currentCarePlanProviderName) &&
                Boolean(session?.therapist?.preferredName) &&
                currentCarePlanProviderName !==
                  session?.therapist?.preferredName && (
                  <Tooltip
                    position="right"
                    content={`${formatPossessiveNoun(
                      session?.patientName.split(' ')[0]
                    )} usual therapist ${
                      sessionIsInThePast ? 'was' : 'is'
                    } unavailable for this session. To avoid interruption in the care, ${
                      session?.therapist?.preferredName
                    } ${
                      sessionIsInThePast ? 'was' : 'is'
                    } subbing on behalf of ${currentCarePlanProviderName}.`}
                  >
                    <div className="flex items-center gap-1">
                      <p>Substitute</p>
                      <img src={INFO} alt="info" className="h-4 w-4" />
                    </div>
                  </Tooltip>
                )}
            </div>
          </div>
        </div>
      </div>

      {/* Right */}
      {!currentCondition?.isIep ? (
        <div className="flex flex-col gap-4 xs:gap-2">
          <div className="flex justify-end">
            <div
              className={`${
                showStatus || showInfo ? 'hidden' : ''
              } flex w-full flex-col gap-4 xl:w-1/2 xs:gap-2`}
            >
              <CancelBookingModal
                open={isOpenCancelBookingDialog}
                setOpen={setIsOpenCancelBookingDialog}
                session={session}
              />

              <button
                className={tertiaryButtonClassSmall}
                onClick={addToCalendar}
              >
                <PlusIcon className="h-5 w-5" /> Add to my calendar
              </button>

              {canCancelBooking && (
                <button
                  onClick={() => setIsOpenCancelBookingDialog(true)}
                  className={tertiaryButtonClassSmall}
                >
                  <XCircleIcon className="h-5 w-5" /> Cancel booking
                </button>
              )}

              <Tooltip
                content="This button will become active 15 minutes before the session."
                position="bottom"
                isDisabled={!isStartZoomButtonDisabled}
              >
                <button
                  className={`${primaryButtonClassSmall} w-full`}
                  disabled={isStartZoomButtonDisabled}
                  onClick={startZoom}
                >
                  <PlayIcon className="h-5 w-5" /> Start Zoom
                </button>
              </Tooltip>
            </div>
          </div>
          {differentUserWhoScheduled && (
            <div className="flex w-full items-end justify-end gap-1 xs:justify-start">
              <img src={USER_ICON} alt="school-building" className="h-5 w-5" />
              <p className="text-sm xs:text-xs">
                This session was booked by {session?.scheduledBy?.name}.
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-end justify-end gap-1 xs:justify-start">
          <img src={SCHOOL_ICON} alt="school-building" className="h-5 w-5" />
          <p className="text-sm xs:text-xs">
            This session {sessionIsInThePast ? 'was' : 'is'} managed by the
            school.
          </p>
        </div>
      )}
    </div>
  )
}

export default SessionComponent

export const SessionComponentLoader: React.FC<{
  minimal: boolean
}> = ({ minimal }) => {
  return (
    <div
      className={`${
        minimal
          ? 'rounded-2xl border p-6 xs:p-3'
          : 'border-b pb-8 last:border-0 last:pb-0 xs:pb-4'
      } grid gap-6 border-components-fields bg-white text-text-primary`}
    >
      <p className="h-5 w-1/2 animate-pulse rounded bg-components-fields font-semibold" />
      <p className="h-5 w-2/3 animate-pulse rounded bg-components-fields font-semibold" />
      <p className="h-5 w-3/4 animate-pulse rounded bg-components-fields font-semibold" />
      <p className="h-5 w-full animate-pulse rounded bg-components-fields font-semibold" />
    </div>
  )
}
